export function browser() {
  return typeof window !== "undefined"
}

export const urlParams = () => {
  if (!browser()) return
  const params = new Map()
  window.location.search
    .substr(1)
    .split("&")
    .forEach(param => {
      const arr = param.split("=")
      params.set(arr[0], arr[1])
    })
  return params
}
